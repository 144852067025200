import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactDOM from "react-dom/client";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Home from "./components/Home";
import Code from "./components/Code";
import reportWebVitals from "./reportWebVitals";

const theme = createTheme({
  palette: {
    primary: {
      light: "#ff5c9d",
      main: "#FF5757",
      dark: "#c50045",
      contrastText: "#ffffff",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        html, body, #root {
          height: 100%;
        }
        body {
          color: #666;
        }
        h1 {
          font-size: 36px;
          font-weight: 500;
          margin: 0;
          color: #000;
          text-transform: uppercase;
          letter-spacing: 3px;
        }
        h2, h3 {
          color: #000;
          font-weight: 400;
        }
      `,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path={"/"} element={<Home />} />
          <Route path={"/bodyforge"} element={<Home />} />
          <Route path={"/dumptrump"} element={<Home />} />
          <Route path={"/rec"} element={<Home />} />
          <Route path={"/partypoints"} element={<Home />} />
          <Route path={"/code"} element={<Code />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
