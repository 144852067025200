import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box, Link } from "@mui/material";
import Bodyforge from "../assets/Bodyforge.png";
import DumpTrump from "../assets/DumpTrump.png";
import PartyPoints from "../assets/PartyPoints.png";
import Rec from "../assets/Rec.png";

const Home = () => {
  const projectId = useLocation().pathname.substring(1);
  useEffect(() => {
    if (projectId.length > 0) {
      setTimeout(()=> {
        document.querySelector("#" + projectId).scrollIntoView({ behavior: "smooth", block: "start" });
      }, 300);
    }
  }, []);

  return (
    <Box sx={{ padding: "40px 20px", maxWidth: 1200, margin: "0 auto" }}>
      <Box component="h1" sx={{ fontSize: { xs: 48, sm: 84 }, letterSpacing: 4, textAlign: "center" }}>Ummtech</Box>
      <Box sx={{ marginTop: 8 }}>
        <Box sx={{ maxWidth: 600, margin: "0 auto" }}>
          <Box component="h1" id="bodyforge">1/ <Link href="https://www.bodyforge.com">BODYFORGE</Link></Box>
          <i>Released on September 30, 2017</i>
          <p>Bodyforge is the most effective way to lose fat and build muscle. Learn how to meal prep and lift weights with step-by-step instruction from your coach. The program is comprehensive, science-based, and adjusts your diet to keep you on track with your goals.</p>
        </Box>
        <Box component="img" alt="Bodyforge" src={Bodyforge} sx={{ width: "100%", marginTop: 2 }} />
      </Box>
      <Box sx={{ marginTop: { xs: 2, sm: 8 } }}>
        <Box sx={{ maxWidth: 600, margin: "0 auto" }}>
          <Box component="h1" id="dumptrump">2/ DUMP TRUMP</Box>
          <i>Released on March 21, 2020</i>
          <p>A nonprofit political organization with the mission to increase voter turnout and send Trump packing.</p>
          <p>Dump Trump made voter registration fast and simple. It reduced the registration process into a series of simple questions. If the person wasn’t eligible to register online, we mailed them a prefilled voter registration form with a prepaid return envelope — they just had to sign the form and drop it in a mailbox.</p>
          <p>For those already registered to vote, the website generated a personalized referral link that they could share with their friends and family via text, Instagram, Facebook, Twitter, etc. They could also donate to our affiliated PAC (Rise Up Fund) which helped finance and promote the website. Donors who contributed $25 or more received an official Dump Trump t-shirt featuring a personalized QR code with their referral link.</p>
          <p>Check out our <Link href="https://medium.com/dump-trump/introducing-dump-trump-3c96c4a383ab">blog post</Link> to learn more.</p>
        </Box>
        <Box component="img" alt="Dump Trump" src={DumpTrump} sx={{ width: "100%", marginTop: 2 }} />
      </Box>
      <Box sx={{ marginTop: { xs: 2, sm: 8 } }}>
        <Box sx={{ maxWidth: 600, margin: "0 auto" }}>
          <Box component="h1" id="rec">3/ <Link href="https://rec.page">REC</Link></Box>
          <i>Released on March 1, 2022</i>
          <p>While recommendations are everywhere, and increasingly powered by algorithms, it’s often the recommendations from people we know and trust that matter most.</p>
          <p>Rec is a simple website that allows you to search for any Twitter user and see their top book and podcast recommendations (with no ads or affiliate links). If there are no recs, you can tweet at the person to add them (they’re easy to add thanks to the Google Books and Spotify APIs).</p>
          <p>People can easily add a link to their own rec page on their social media profiles for others to explore.</p>
        </Box>
        <Box component="img" alt="Rec" src={Rec} sx={{ width: "100%", marginTop: 2 }} />
      </Box>
      <Box sx={{ marginTop: { xs: 2, sm: 8 } }}>
        <Box sx={{ maxWidth: 600, margin: "0 auto" }}>
          <Box component="h1" id="partypoints">4/ <Link href="https://partypoints.org">PARTY POINTS</Link></Box>
          <i>Released on October 3, 2022</i>
          <p>Party Points is a free tool for middle and high school educators to incentivize and reward students who show up, engage with their classwork, and respect others. Students who earn enough points get to participate in a fun group activity.</p>
          <p>Students can sign in with their Google accounts to see how many points they’ve earned and to see why they may have lost or gained points. Student and class information comes from Google Classroom.</p>
        </Box>
        <Box component="img" alt="Party Points" src={PartyPoints} sx={{ width: "100%", marginTop: 2 }} />
      </Box>
      <Box sx={{ marginTop: { xs: 2, sm: 8 } }}>
        <Box sx={{ maxWidth: 600, margin: "0 auto" }}>
          <Box component="h1" id="code">5/ <Link href="code">LEARN HOW TO CODE</Link></Box>
          <i>Written on December 23, 2017</i>
          <p>A short guide for anyone interested in learning how to code.</p>
        </Box>
      </Box>
      <Box sx={{ marginTop: 8, textAlign: "center" }}>
        <Link href="mailto:rocky@ummtech.org">CONTACT</Link>&nbsp;&nbsp;&nbsp;© 2022 UMMTECH LLC
      </Box>
    </Box>
  );
};

export default Home;