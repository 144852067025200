import React from "react";
import { Box, Link } from "@mui/material";

const Code = () => {
  return (
    <Box sx={{ padding: "40px 20px", maxWidth: 600, margin: "0 auto" }}>
      <h1>Learn how to code</h1>
      <h2>Why code?</h2>
      <Box sx={{ position: "relative", width: "100%", height: 0, paddingBottom: "56%" }}>
        <Box component="iframe" src="https://www.youtube.com/embed/nKIu9yen5nc?rel=0" frameBorder="0" gesture="media" allow="encrypted-media" allowFullScreen sx={{ position: "absolute", width: "100%", height: "100%", left: "0", top: "0" }} />
      </Box>
      <h2>How to code</h2>
      <h3>Step 1</h3>
      <p>Head over to <Link href="https://code.org/student/middle-high">https://code.org/student/middle-high</Link> and tap on the Express Course. This course is more like a game, but it will introduce some important coding concepts. If it feels too basic, don’t feel like you have to finish it, just skip ahead to the next step.</p>
      <h3>Step 2</h3>
      <p>There are many programming languages you can learn, but the most popular are probably JavaScript and Python. The good news is that once you learn one language, learning others will be much easier.</p>
      <p>I suggest starting with JavaScript, which is the language of the web. If you ever want to make a website from scratch, you’ll need to know JavaScript.</p>
      <p>The best place to learn JavaScript is probably Codecademy. To get started, just go to <Link href="https://www.codecademy.com/learn/introduction-to-javascript">https://www.codecademy.com/learn/introduction-to-javascript</Link> and tap Start. You’ll need to create an account, but then you can jump right into the first lesson.</p>
      <p>Codecademy is great because you get to start writing actual code. Not everything will make sense, but that’s okay, just take the time to read everything and do the best you can. Coding is like any other skill, it becomes more interesting as you become more proficient — just make sure you stick with it.</p>
      <p>After the JavaScript course, I’d suggest going through all the web development courses at <Link href="https://www.codecademy.com/catalog/subject/web-development">https://www.codecademy.com/catalog/subject/web-development</Link>. These courses will teach you everything you need to know to build a website — which would be a great first project to test your skills!</p>
      <h3>Step 3</h3>
      <p>Start exploring other free online courses. One great option is Khan Academy, which has a format very similar to Codecademy, but you’ll learn new things. Just head over to <Link href="https://www.khanacademy.org/computing/computer-programming">https://www.khanacademy.org/computing/computer-programming</Link> to get started.</p>
      <h2>Continued learning</h2>
      <p>At this point, you might decide you’d like to learn other technologies. For instance, if you want to make iPhone games, you’ll want to learn Swift and SpriteKit. You’ll also need a MacBook because you’ll be using a program called Xcode to write apps.</p>
      <p>Or maybe you’re interested in learning Python. Python is a very powerful language that could help you build more sophisticated websites or apps. It’s a fun language to learn, has an active community, and is used heavily by companies like Google, Instagram, Lyft, etc.</p>
      <p>If you want to stick with web development, but would like to dive deeper, it may be worth getting a Pro account on Codecademy. Just go to <Link href="https://www.codecademy.com/pro/membership">https://www.codecademy.com/pro/membership</Link> and tap Upgrade Now. It costs $19.99 a month, but allows you to take their more intensive programs like, “Build Websites from Scratch” or “Build Front-End Web Applications from Scratch.”</p>
      <p>You can also pick up a book like <Link href="https://www.amazon.com/JavaScript-Good-Parts-Douglas-Crockford/dp/0596517742">https://www.amazon.com/JavaScript-Good-Parts-Douglas-Crockford/dp/0596517742</Link>. Books are the best way to master any technology, just remember to get one appropriate for your level (it’s better to err on the side of beginner-friendly). It’s also a really good idea to actually write code as you go, otherwise you’re less likely to retain what you’ve learned.</p>
      <h2>If you get stuck</h2>
      <p>Believe it or not, engineers rely heavily on Google when they get stuck. There is a wealth of knowledge on the Internet, it’s just a matter of digging for the answers.</p>
      <p>Don’t let yourself get overwhelmed. Coding is like a sport or anything else — you have to practice, practice, practice. For most people, the best way to learn is to just make things. Pick a project — like to make a tic-tac-toe game — and focus on learning what you need to learn to complete that project. Once you’ve done that, pick a new, slightly more ambitious project, and repeat. This is the learning process and it never ends.</p>
      <p>With all that said, feel free to email me at <Link href="mailto:rocky@ummtech.org">rocky@ummtech.org</Link> if you have any questions!</p>
    </Box>
  );
};

export default Code;